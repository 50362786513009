.navbar-brand > img {
    max-height: 30px;
}

.card-header {
    background-color: $green-repofarma;
    color: white;
}

.btn-primary {
    background-color: $green-repofarma !important;
    border-color: lighten($green-repofarma, 10%) !important;

    &:active, &:focus, &:hover{
        border-color: darken($green-repofarma, 10%) !important;
    }
}

.required:after {
    content: " *";
    color: red;
    font-weight: 100;
}

.mb-10 {
    margin-bottom: 10px;
}
